import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Web Development",
      desc: "I love to design and develop amazing web experiences.",
      icon: "fas fa-desktop",
    },
    {
      name: "App Development",
      desc: "Mobile App Development is one of my favourite areas to develop, creating native apps for iOS and Android is one of my stongest skillsets.",
      icon: "fas fa-mobile",
    },
    {
      name: "Game Design & Development",
      desc: "My Initial background as a developer is in Game Development. It continues to be one of my core passions.",
      icon: "fas fa-gamepad",
    },
    {
      name: "UI/UX Design",
      desc: "UI/UX Design was my main source of research throughout University, I can draw on this knowledge to create enjoyable interactive experiences",
      icon: "fas fa-window-restore",
    },
    {
      name: "2D & 3D Game Assets",
      desc: "I can create both 2D and 3D game assets, working in a small game development team gives you many extra skills!",
      icon: "fas fa-cube",
    },
    {
      name: "Graphic Design",
      desc: "Graphic design is a real passion of mine. I have strong branding skills and have designed branding for many clients.",
      icon: "fas fa-paint-brush",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          My strongest skillsets
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
