import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    WEB: "Web Dev",
    GAME: "Game Dev",
    APP: "App Dev",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    // MY PROJECTS //

    ///////////
    // GAMES //
    ///////////

    // Shipwrecked - iOS
    {
      title: "Shipwrecked",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A Retro style arcade game. Play as a shipwrecked pirate as you try to avoid hungry sharks, angry octopuses and attacking pufferfish. Fast addictive arcade action.",
          client: "Tiger Moon Interactive",
        technologies: "iOS, Android, C#, Unity, XCode",
        industry: "Mobile",
        date: "March, 2020",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/ShipWrecked",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/shipwrecked.jpg",

      categories: [filters.GAME],
    },

    // Underworld - iOS
    {
      title: "Underworld",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A Retro style 2D Platformer with a lot of character. Developed for Tiger Moon Interactive. Play as Cryptus the skeleton as you try to escape the Underworld, one Dungeon at a time.",
        client: "Tiger Moon Interactive",
        technologies: "iOS, Android, C#, Unity, XCode",
        industry: "Mobile",
        date: "July, 2020",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/UnderWorld",
        },
        

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/underworld.jpg",

      categories: [filters.GAME],
    },

    // Untold Tracks - Desktop
    {
      title: "Untold Tracks",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Embark on an immersive journey in this 3D first-person survival and adventure game. Awakening in a mysterious forest with no memory of your arrival, you encounter the ominous silhouette of a ruined and colossal steam engine. Uncover the secrets shrouded in this realm by traversing uncharted tracks, where your quest leads you to restore the ancient engine to its original glory and beyond. Dive into the mystery that unfolds as you explore and rebuild in this captivating immersive experience.",
        client: "Tiger Moon Interactive",
        technologies: "Steam, Windows, Mac, C#, Unity",
        industry: "Desktop",
        date: "May, 2022",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/UntoldTracks"
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/untold_tracks.jpg",

      categories: [filters.GAME],
    },

    // The Yeti
    {
      title: "The Yeti",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Stop the crazy Yeti Hunters from slaying yeti. Smash the climbing hunters from Yeti's pillar of ice in this simple but addictive retro pixel game and crank up your score to unlock special Yeti costumes. Use the new 'Gold Mode' power-up to elevate your high score to the next level and make it rain hunter bones! (literally).",
          client: "Tiger Moon Interactive",
        technologies: "iOS, Android, C#, Unity, XCode",
        industry: "Mobile",
        date: "Feb, 2018",
        url: {
          name: "iOS App Store",
          link: "https://apps.apple.com/lv/app/the-yeti/id900236234",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/yeti.jpg",

      categories: [filters.GAME],
    },

    // 1-Bit Cinema - Desktop
    {
      title: "1-Bit Cinema",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Dive into the retro charm of 1-Bit Cinema, a pixelated cinema tycoon game where you build and manage your own theaters. Curate a collection of 1-bit films, upgrade your venues, and navigate the challenges of the evolving film industry. Become a curator of cinematic history, blending nostalgia with strategic management to create a beloved empire of pixelated entertainment.",
          client: "Personal",
          technologies: "Steam, Windows, Mac, C#, Unity",
        industry: "Desktop",
        date: "Dec, 2022",
        url: {
          name: "GitHub Repo",
          link: "https://github.com/brad-morrison/1BitCinema-Steam",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/1bitcinema.jpg",

      categories: [filters.GAME],
    },

    // Totem
    {
      title: "Totem",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Embark on a whimsical journey with Totem, a mobile game where you strategically position totems to appease animal spirits. Challenge your intuition, master totem arrangement, and embrace the electrifying excitement of this lightning-paced adventure.",
        client: "Personal",
        technologies: "iOS, Android, C#, Unity, XCode",
        industry: "Mobile",
        date: "Oct, 2023",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/Totem",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/totem.jpg",

      categories: [filters.GAME],
    },

    //////////
    // APPS //
    //////////

    // Sounds of Scotland
    {
      title: "Sounds of Scotland",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Sounds of Scotland is an app designed to transport users to the stunning Scottish wilderness through immersive audio recordings. With a simple interface, users can easily browse and listen to a range of atmospheric sounds from serene locations across the country.",
        client: "Personal",
        technologies: "iOS, SwiftUI, Swift, Rive, XCode",
        industry: "Mobile & Mac",
        date: "Mar, 2024",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/SoundsOfScotland-iOS",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/sounds.jpg",

      categories: [filters.APP],
    },

    // Time Task
    {
      title: "Time Task",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Introducing TimeTask, the ultimate task management app that empowers users to effortlessly track multiple tasks on a single screen. With the ability to set and manage multiple stopwatches simultaneously, you can efficiently monitor various activities in real-time. Seamlessly switch between tasks, stay organized, and enhance productivity with this user-friendly app designed to make time management a breeze. Elevate your task-tracking experience with TimeTask and conquer your day with precision and ease.",
        client: "Personal",
        technologies: "iOS, Android, C#, Swift, SwiftUI, XCode",
        industry: "Art & Design",
        date: "Aug, 2022",
        url: {
          name: "GitHub Repo",
          link: "https://github.com/brad-morrison/TimeTask-iOS",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/timetask.jpg",

      categories: [filters.APP],
    },

    // Wind Tab
    {
      title: "Wind Tab",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Create and store personalized tablature for wind instruments, showcasing your unique compositions. Dive into a vast library of user-generated tabs, ready for download to inspire your next performance. Whether you're a seasoned musician or a budding artist, WindTab is your go-to platform for sharing, discovering, and embracing the beautiful world of wind instrument tablature. Elevate your musical journey with a community that breathes life into your melodies and harmonies.",
        client: "Freelance Client",
        technologies: "iOS, Android, Swift, SwiftUI, XCode",
        industry: "Mobile & Mac",
        date: "Apr, 2019",
        url: {
          name: "GitHub Repo",
          link: "https://github.com/brad-morrison/WindTab-iOS",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/tab.jpg",

      categories: [filters.APP],
    },

    // Visualise Code App
    {
      title: "Visualise Code",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "VisualiseCode, your go-to destination for novice programmers eager to master the art of coding! Our user-friendly website and app provide interactive, hands-on coding tutorials, making learning to code a fun and engaging experience. From the basics of programming languages to real-world projects, VisualiseCode guides you step-by-step, building a solid foundation for your coding journey. Track your progress, unlock achievements, and connect with a vibrant community of learners. Whether you're a complete beginner or looking to enhance your skills, VisualiseCode is the launchpad for your coding success. Start coding today and watch your programming prowess unfold!",
        client: "University of Dundee",
        technologies: "iOS, Android, C#, SwiftUI, Swift, XCode, ReactJS",
        industry: "Mobile & Web",
        date: "Nov, 2021",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/VisualiseCode.io",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/visualise_code_app.jpg",

      categories: [filters.APP],
    },

    // Power Pulse
    {
      title: "Power Pulse",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Introducing Power Pulse, your ultimate fitness companion for tracking and creating dynamic strength workouts. With an intuitive app interface and a robust set of features, Power Pulse allows users to seamlessly track their strength training progress, set personal records, and design custom workouts tailored to individual goals.",
        client: "Anonymous",
          technologies: "iOS, Android, C#, SwiftUI, Swift, XCode",
        industry: "Mobile & Mac",
        date: "July, 2020",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/PowerPulse-iOS",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/power_pulse.jpg",

      categories: [filters.APP],
    },

    //////////////
    // WEBSITES //
    //////////////

    // Visalise Code
    {
      title: "Visualise Code",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "VisualiseCode, your go-to destination for novice programmers eager to master the art of coding! Our user-friendly website and app provide interactive, hands-on coding tutorials, making learning to code a fun and engaging experience. From the basics of programming languages to real-world projects, VisualiseCode guides you step-by-step, building a solid foundation for your coding journey. Track your progress, unlock achievements, and connect with a vibrant community of learners. Whether you're a complete beginner or looking to enhance your skills, VisualiseCode is the launchpad for your coding success. Start coding today and watch your programming prowess unfold!",
        client: "University of Dundee",
        technologies: "iOS, Android, C#, Unity, XCode, ReactJS",
        industry: "Mobile & Web",
        date: "July 16, 2019",
        url: {
          name: "GitHub Repo",
          link: "https://github.com/brad-morrison/VisualiseCode.io",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/visualise_code.jpg",

      categories: [filters.WEB],
    },

    // siblings reunited
    {
      title: "Siblings Reunited",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A website designed and developed by myself for Siblings Reunited, a charity that reunites seperated siblings in the care system. I developed the site and created all graphic design assets.",
        client: "Siblings Reunited",
          technologies: "ReactJS, JavaSCript, HTML, CSS, AWS",
        industry: "Web",
        date: "Jan, 2023",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/SiblingsReunited.org.uk-v2",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/siblings_reunited.jpg",

      categories: [filters.WEB],
    },

    // siblings reunited
    {
      title: "The Scottish Gazette",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A fictional Scottish news paper that I created to practice my web development skills and try out some new concepts.",
          client: "Personal",
        technologies: "iOS, Android, C#, Unity, XCode",
        industry: "Web",
        date: "August, 2019",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/TheScottishGazette",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/scottish_gazette.jpg",

      categories: [filters.WEB],
    },

    // tortuga coffee
    {
      title: "Tortuga Coffee",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A website designed for an up and coming coffee company based out of Mexico. I designed the website with them and started the development process but they decided not to pursue the business.",
        client: "Tortuga Coffee",
          technologies: "HTML, JavaScript, CSS",
        industry: "Web",
        date: "July, 2017",
        url: {
          name: "Github Repo",
          link: "https://github.com/brad-morrison/TortugaCoffee",
        },

        sliderImages: [
          //"images/projects/project-2.jpg",
          //"images/projects/project-5.jpg",
        ],
      },

      thumbImage: "images/projects/tortuga_coffee.jpg",

      categories: [filters.WEB],
    },

    // - END - MY PROJECTS //
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
